<template>
  <div>
    <el-dialog
      :title="title + '角色'"
      :visible.sync="dialogVisible"
      width="450px"
    >
      <el-form
        :model="userFrom"
        :rules="rules"
        ref="userFrom"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="userFrom.roleName"></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="roleState">
          <el-radio v-model="userFrom.roleState" :label="101">启用</el-radio>
          <el-radio v-model="userFrom.roleState" :label="102">禁用</el-radio>
        </el-form-item>
        <el-form-item label="说明">
          <el-input v-model="userFrom.roleDescription"></el-input>
        </el-form-item>

        <el-form-item label="是否显示该角色" prop="isShow">
          <el-radio v-model="userFrom.isShow" :label="0">否</el-radio>
          <el-radio v-model="userFrom.isShow" :label="1">是</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="colse">取 消</el-button>
        <el-button type="primary" @click="submitForm('userFrom')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      dialogVisible: false,
      userFrom: {
        roleName: '',
        roleState: 101,
        roleDescription: '',
        userAccount: '',
        isShow: 1,
        id: '',
      },
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ],
        roleState: [
          { required: true, message: '请选择角色状态', trigger: 'blur' },
        ],
        isShow: [
          { required: true, message: '是否显示该角色', trigger: 'blur' },
        ],
      },
      isimg: false,
      title: '新增',
    }
  },

  created() {},

  mounted() {},

  methods: {
    init(val, row) {
      this.title = val
      this.dialogVisible = true
      if (val == '新增') {
        this.userFrom = {}
      } else {
        this.userFrom = JSON.parse(JSON.stringify(row))
      }
      let login = JSON.parse(sessionStorage.getItem('login'))
      this.userFrom.userAccount = login.accountNumber
      // console.log(JSON.parse(login), '====>login')
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    colse() {
      this.userFrom = {
        roleName: '',
        roleState: 101,
        roleDescription: '',
        userAccount: '',
        isShow: 1,
        id: '',
      }
      this.dialogVisible = false
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.userFrom.id) {
            let params = {
              // code: "",
              // data: {
              // userId: this.userFrom.userId,
              // userName: this.userFrom.userName,
              // userAccount: this.userFrom.userAccount,
              // password: this.userFrom.password,
              ...this.userFrom,

              // },
              // time: "",
            }
            let { data } = await this.$base.postAjax(
              '/user/role/addRole',
              params
            )
            if (data.code == 200) {
              this.$message.success('成功')
              this.dialogVisible = false
              this.$emit('onSubmit')
            }
          } else {
            let params = {
              // code: "",
              // data: {
              // userId: this.userFrom.userId,
              // userName: this.userFrom.userName,
              // userAccount: this.userFrom.userAccount,
              // password: this.userFrom.password,
              ...this.userFrom,
              // },
              // time: "",
            }
            let { data } = await this.$base.postAjax(
              '/user/role/updateRole',
              params
            )
            if (data.code == 200) {
              this.$message.success('成功')
              this.dialogVisible = false
              this.$emit('onSubmit')
            }
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dif {
  display: flex;
}
.attachmentL {
  position: relative;
  margin-right: 10px;
}
.imgwh {
  width: 80px;
  height: 80px;
}
.mask {
  position: absolute;
  right: 7px;
  top: 3px;
  .el-icon-delete {
    font-size: 20px;
    cursor: pointer;
  }
}
.addbox {
  border: 1px dashed #d9d9d9;
  width: 80px;
  height: 80px;
}
.avatar-uploader-icon,
el-icon-delete {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
</style>
