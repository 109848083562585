<template>
  <div>
    <el-dialog title="权限分配" :visible.sync="dialogVisible" width="450px">
      <el-form
        :model="userFrom"
        :rules="rules"
        ref="userFrom"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="权限" prop="userAccount">
          <!-- <el-checkbox-group v-model="checkList"> -->
          <el-checkbox
            style="width: 100%"
            v-model="item.state"
            :false-label="2"
            :true-label="1"
            v-for="(item, index) in mentListData"
            :key="index"
            v-if="item.menuState == 101"
            >{{ item.menuName }}</el-checkbox
          >
          <!-- </el-checkbox-group> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="colse">取 消</el-button>
        <el-button type="primary" @click="submitForm('userFrom')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as userApi from '@/api/user'
export default {
  components: {},

  data() {
    return {
      checkList: [],
      dialogVisible: false,
      userFrom: {
        userId: '',
        userName: '',
        userAccount: '',
        password: '',
      },
      rules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        userAccount: [
          { required: true, message: '请输入用户账号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入用户密码', trigger: 'blur' },
        ],
      },
      isimg: false,
      mentListData: [],
      mentListDataTo: [],
      roleData: {},
      pageToList: [],
    }
  },

  created() {},

  mounted() {},

  methods: {
    init(row) {
      console.log(row)
      this.roleData = row
      // let login = JSON.parse(sessionStorage.getItem('login'))
      let params = {
        data: {
          menuName: '',
          pid: -1,
        },
        page: 1,
        pageSize: 999,
      }
      userApi.mentList(params).then((res) => {
        console.log(res, '====res')
        this.mentListData = []
        res.data.data.map((item) => {
          this.mentListData.push({ ...item, state: 2 })
          if (item.child.length) {
            item.child.map((i) => {
              this.mentListData.push({ ...i, state: 2 })
            })
          }
        })
        // this.mentListData.forEach((item) => {
        //   item.state = 1
        // })
        userApi
          .getRoleMenu({ roleName: row.roleName, roleId: row.id })
          .then((res) => {
            console.log(res, 'res===>')
            this.pageToList = JSON.parse(JSON.stringify(res.data.menus))
            this.dialogVisible = true
            this.mentListData.forEach((item) => {
              res.data.menus.forEach((i) => {
                if (item.menuName === i.menuName) {
                  item.state = 1
                }
              })
            })
          })
      })
      // let params = {
      //   userAccount: login.accountNumber,
      //   userId: login.userId,
      // }
      // userApi.getInfo(params).then((res) => {
      //   console.log(res, '====res')

      // })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    colse() {
      this.userFrom = {
        userId: '',
        userName: '',
        userAccount: '',
        password: '',
      }
      this.dialogVisible = false
    },
    async submitForm(formName) {
      this.mentListDataTo = this.mentListData
      // this.pageToList
      console.log(this.pageToList, 'this.pageToList')
      let menus = []
      if (this.pageToList.length) {
        this.mentListDataTo.forEach((item) => {
          this.pageToList.forEach((i) => {
            if (item.menuName !== i.menuName && item.state == 2) {
              item.state = 0
            }
          })
        })
        menus = this.mentListDataTo
      } else {
        // this.mentListDataTo =

        this.mentListDataTo.map((item) => {
          if (item.state == 1) {
            menus.push(item)
          }
        })
      }
      console.log(menus, 'menus')
      // return
      let params = {
        menus: menus,
        roleName: this.roleData.roleName,
        roleId: this.roleData.id,
        // code: "",
        // data: {
        // },
        // time: "",
      }
      let { data } = await this.$base.postAjax('/user/role/addRoleMenu', params)
      if (data.code == 200) {
        this.$message.success('成功')
        this.dialogVisible = false
        this.$emit('onSubmit')
      }
    },
    stateChange(item) {
      console.log(item)
      if (item.state == 1) {
        item.state = 2
      } else {
        item.state = 1
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dif {
  display: flex;
}
.attachmentL {
  position: relative;
  margin-right: 10px;
}
.imgwh {
  width: 80px;
  height: 80px;
}
.mask {
  position: absolute;
  right: 7px;
  top: 3px;
  .el-icon-delete {
    font-size: 20px;
    cursor: pointer;
  }
}
.addbox {
  border: 1px dashed #d9d9d9;
  width: 80px;
  height: 80px;
}
.avatar-uploader-icon,
el-icon-delete {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
</style>
